<template>
  <div class="row">
    <div class="col-md-12 card p-0">
      <div class="card-body row p-0">
        <div class="col-sm-12">
          <my-filter :rules="rules" v-on:filter-change="updateFilter"></my-filter>
        </div>
        <div class="col-sm-12">
          <my-table :columnDefs="columnDefs" v-bind:data-rows="activities" :actions="actions" :paginate="paginate"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Message } from 'element-ui';
  import MyTable from 'src/components/UIComponents/Table.vue'
  import orderSchemas from './log-schemas'
  import MyFilter from 'src/components/UIComponents/Filter.vue'
  import dtHelper from 'src/helpers/datatable'

  export default {
    components: {
      MyTable,
      MyFilter,
      Message
    },
    computed: {
      activities() {
        let rows = this.$store.state.activities || [];
        rows.map((item) => {
          if (item.object_id < 1) item.object_id = '';
        });
        return rows;
      },
      paginate() {
        let item = this.$store.state.activities || {};
        item.type = 'activities';
        return item;
      }
    },
    data() {
      return {
        filterOutput: [],
        columnDefs: dtHelper.buildColumDefs(orderSchemas),
        filter: {},
        rules: dtHelper.buildRules(orderSchemas),
        actions: []
      }
    },
    methods: {
      updateFilter(filterOutput) {
        const filterString = dtHelper.buildQueryString(filterOutput);
        this.$store.dispatch('fetchLogs', {
          filterString
        });
      },
      edit(index, row) {
        if (!row.object_id) return;
        let url = '/orders/' + row.object_id;
        if (row.object == 'race') url = '/races/' + row.object_id;
        else if (row.object == 'user') url = '/users' + row.object_id;
        this.$router.push(url);
      }
    },
    mounted() {
      this.$store.dispatch('fetchLogs');
      this.$store.dispatch('setPageTitle', 'Lịch sử hoạt động');
      this.$store.dispatch('setCurrentFilterUsers', []);
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>
<style lang="scss">
  .el-table .td-actions{
    button.btn {
      margin-right: 5px;
    }
  }
</style>
