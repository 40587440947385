export default [
  {
    prop: 'id',
    label: '#',
    minWidth: 100,
    type: 'number'
  },
  {
    prop: 'name',
    label: 'User',
    minWidth: 180,
    type: 'text'
  },
  {
    prop: 'object',
    label: 'Đối tượng',
    minWidth: 180,
    type: 'text',
  },
  {
    prop: 'object_id',
    label: 'Mã',
    minWidth: 180,
    type: 'text',
    object: 'activity'
  },
  {
    prop: 'action',
    label: 'Hành động',
    minWidth: 180,
    type: 'badge'
  },
  {
    prop: 'created_at',
    label: 'Thời gian',
    minWidth: 180,
    type: 'datetime'
  }
]
